import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Spacers is good spacing systems are based on simple mathematical principles
with increments that are visually. This toolkit gives designers and developers
guidelines for effectively applying layout spacing, resulting in a more
consistent application of space across our product. You can chek out the guideline for more detail description.`}</p>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Space`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixels`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing6" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing7" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing8" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing9" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing10" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing11" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing12" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing13" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing13`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing14" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing14`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing15" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing15`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing16" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing16`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing17" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing17`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing18" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing18`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`144dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`144px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing19" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing19`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing20" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`176dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`176px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing21" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing21`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`192dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`192px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing22" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing22`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`208dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`208px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing23" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing23`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`224dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`228px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing24" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing24`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing25" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing25`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing26" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing26`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing27" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing27`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing28" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/spacing28`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`384dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`384px`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`android: paddingTop = "?attr/spacing1";
android: paddingTop = "?attr/spacing2";
android: paddingTop = "?attr/spacing3";
android: paddingTop = "?attr/spacing4";
android: paddingTop = "?attr/spacing5";
android: paddingTop = "?attr/spacing6";
android: paddingTop = "?attr/spacing7";
android: paddingTop = "?attr/spacing8";
android: paddingTop = "?attr/spacing9";
android: paddingTop = "?attr/spacing10";
android: paddingTop = "?attr/spacing11";
android: paddingTop = "?attr/spacing12";
android: paddingTop = "?attr/spacing13";
android: paddingTop = "?attr/spacing14";
android: paddingTop = "?attr/spacing15";
android: paddingTop = "?attr/spacing16";
android: paddingTop = "?attr/spacing17";
android: paddingTop = "?attr/spacing18";
android: paddingTop = "?attr/spacing19";
android: paddingTop = "?attr/spacing20";
android: paddingTop = "?attr/spacing21";
android: paddingTop = "?attr/spacing22";
android: paddingTop = "?attr/spacing23";
android: paddingTop = "?attr/spacing24";
android: paddingTop = "?attr/spacing25";
android: paddingTop = "?attr/spacing26";
android: paddingTop = "?attr/spacing27";
android: paddingTop = "?attr/spacing28";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      